// extracted by mini-css-extract-plugin
export var blogsCard = "blogs-module--blogs-card--a5846";
export var blogsCardDate = "blogs-module--blogs-card-date--a3630";
export var blogsCardImage = "blogs-module--blogs-card-image--72fcf";
export var blogsCardImageWrapper = "blogs-module--blogs-card-image-wrapper--da904";
export var blogsCardLink = "blogs-module--blogs-card-link--277fe";
export var blogsCardTag = "blogs-module--blogs-card-tag--170db";
export var blogsCardText = "blogs-module--blogs-card-text--9f88a";
export var blogsCardTitle = "blogs-module--blogs-card-title--0c830";
export var blogsCardWrapper = "blogs-module--blogs-card-wrapper--bbf92";
export var blogsContainer = "blogs-module--blogs-container--88afa";
export var blogsHr = "blogs-module--blogs-hr--39fd2";
export var blogsPagination = "blogs-module--blogs-pagination--54e2d";
export var blogsPaginationCurrentPage = "blogs-module--blogs-pagination-current-page--25e89";
export var blogsPaginationLink = "blogs-module--blogs-pagination-link--a4061";
export var blogsTitle = "blogs-module--blogs-title--3441e";