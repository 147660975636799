import * as React from 'react'
import { Link, graphql } from 'gatsby'
import Layout from '../components/layout'
import * as styles from '../styles/blogs.module.css'

const Blogs = (props) => {
  const blogPosts = props.data.allContentfulBlogPost.edges
  const { currentPage, numPages } = props.pageContext
  const isFirst = currentPage === 1
  const isLast = currentPage === numPages
  const prevPage = currentPage - 1 === 1 ? "/blogs" : `/blogs/${(currentPage - 1).toString()}`
  const nextPage = `/blogs/${(currentPage + 1).toString()}`

  return (
    <Layout
      isHome={false}
      title="Mond Blog - 自家焙煎コーヒーとバタートースト"
      description="名古屋市中区の鶴舞・千代田エリアの自家焙煎コーヒーとバタートーストのカフェ「Mond（モーント）」のブログです。最新情報や新作メニュー、ワークショップや、Parlor（パーラー）と題したお話し会のお知らせなどをお届けします。"
    >
      <div className={styles.blogsContainer}>
        <h1 className={styles.blogsTitle}>Mond blog</h1>
        <hr className={styles.blogsHr}/>
        <div className={styles.blogsCardWrapper}>
          {blogPosts.map(({ node: post }) => (
            <div key={post.id} className={styles.blogsCard}>
              <Link to={`/blog/${post.slug}`} className={styles.blogsCardLink}>
                <div className={styles.blogsCardImageWrapper}>
                  <img
                    className={styles.blogsCardImage}
                    alt={post.title}
                    src={post.thumbnail.file.url}
                  />
                  {post.tag && <div className={styles.blogsCardTag}>{`#${post.tag.title}`}</div>}
                </div>
              </Link>
              <p className={styles.blogsCardDate}>{post.publishedDate}</p>
              <h2 className={styles.blogsCardTitle}>{post.title}</h2>
              <p className={styles.blogsCardText}>{post.description.description}</p>
            </div>
          ))}
        </div>
        <div className={styles.blogsPagination}>
          {!isFirst && <Link className={styles.blogsPaginationLink} to={prevPage} rel="prev">← prev</Link>}
          {Array.from({ length: numPages }, (_, i) => (
            <Link className={styles.blogsPaginationLink} key={`pagination-number${i + 1}`} to={`/blogs/${i === 0 ? "" : i + 1}`}>
              <p className={styles.i + 1 === currentPage ? styles.blogsPaginationCurrentPage : null}>{i + 1}</p>
            </Link>
          ))}
          {!isLast && <Link className={styles.blogsPaginationLink} to={nextPage} rel="next">next →</Link>}
        </div>
        <hr className={styles.blogsHr}/>
      </div>
    </Layout>
  )
}

export default Blogs

export const query = graphql`
  query BlogsQuery($skip: Int!, $limit: Int!) {
    allContentfulBlogPost(
      sort: { fields: publishedDate, order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          id
          title
          slug
          publishedDate(formatString: "YYYY.MM.DD")
          description {
            description
          }
          thumbnail {
            file {
              url
            }
          }
          tag {
            title
            slug
          }
        }
      }
    }
  }
`